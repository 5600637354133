type Props = {}

function Separator({ }: Props) {
	return (
		<div className="w-full flex items-center gap-x-4">
			<span className="size-1.5 rounded-full shrink-0 bg-secondary/80 transition-[background-color] ease-linear" />
			<span className="size-1 rounded-full shrink-0 bg-secondary/80 transition-[background-color] ease-linear" />
			<div className="h-0.5 grow bg-palette-4 transition-[background-color] ease-linear" />
			<span className="size-1 rounded-full shrink-0 bg-secondary/80 transition-[background-color] ease-linear" />
			<span className="size-1.5 rounded-full shrink-0 bg-secondary/80 transition-[background-color] ease-linear" />
			<span className="size-1 rounded-full shrink-0 bg-secondary/80 transition-[background-color] ease-linear" />
			<div className="h-0.5 grow bg-palette-4 transition-[background-color] ease-linear" />
			<span className="size-1 rounded-full shrink-0 bg-secondary/80 transition-[background-color] ease-linear" />
			<span className="size-1.5 rounded-full shrink-0 bg-secondary/80 transition-[background-color] ease-linear" />
		</div>
	)
}

// Exports
export default Separator
